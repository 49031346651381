import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@material-ui/core";
  import React, { useState, useEffect } from "react";
  import { i18n } from "../../translate/i18n";
  import { Form, Formik } from "formik";
  import LabelSelect from "../LabelSelect";
  import { makeStyles } from "@material-ui/core/styles";
  import toastError from "../../errors/toastError";
  import { toast } from "react-toastify";
  import api from "../../services/api";
  
  const useStyles = makeStyles((theme) => ({
    root: {},
  
    ticketNumber: {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(1),
      fontSize: 14,
      fontStyle: "italic",
    },
  }));
  
  const LabelAssignTicketModal = ({ open, onClose, ticket }) => {
    const classes = useStyles();
    const [selectedLabelIds, setSelectedLabelIds] = useState([]);
  
    useEffect(() => {
      (async () => {
          if (ticket && ticket.labels) {
            setSelectedLabelIds(ticket.labels.map((label) => label.id));
          }
      })();
    }, [ticket]);
  
    const handleSaveLabel = async () => {
      try {
        await api.post("/labels/assign-ticket", {
          ticketId: ticket.id,
          labelIds: selectedLabelIds,
        });
        toast.success("Label saved successfully");
        handleClose();
      } catch (err) {
        toastError(err);
      }
    };
  
    const handleClose = () => {
      onClose(false);
      setSelectedLabelIds([]);
    };
  
    return (
      <div className={classes.root}>
        <Dialog
          open={open}
          maxWidth="sm"
          fullWidth
          onClose={() => onClose(false)}
        >
          <DialogTitle>
            {i18n.t("labelAssignTicketModal.title")}{" "}
            #{ticket?.id}
          </DialogTitle>
          <Formik
            initialValues={{
              selectedLabelIds: [],
            }}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveLabel(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {() => (
              <Form>
                <DialogContent dividers>
                  <LabelSelect
                    selectedLabelIds={selectedLabelIds}
                    onChange={(selectLabel) => setSelectedLabelIds(selectLabel)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleClose}
                  >
                    {i18n.t("labelAssignTicketModal.buttons.cancel")}
                  </Button>
                  <Button type="submit" color="primary" variant="contained">
                    {i18n.t("labelAssignTicketModal.buttons.ok")}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    );
  };
  
  export default LabelAssignTicketModal;
  