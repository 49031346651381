import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  Chip,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import { getWhiteLabelLogo } from "../config";
import { fetchToken } from "../firebase";
import api from "../services/api";
import { toast } from "react-toastify";
import toastError from "../errors/toastError";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "48px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    backgroundColor: "#F5F5F5",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  userName: {
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  chip: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drawerPaperClose: {
    backgroundColor: "#F5F5F5",
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    minHeight: "48px",
  },
  content: {
    flex: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  chips: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    gap: theme.spacing(1),
  },
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);
  const [notificationPopupOpen, setNotificationPopupOpen] = useState(false);

  useEffect(() => {
    if (document.body.offsetWidth > 1440) {
      setDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      setNotificationPopupOpen(true);
    } else {
      const handleEnableNotifications = async () => {
        if (user && user.id) {
          await fetchToken(
            () => {},
            async (token) => {
              await api
                .get("/subscribe", {
                  params: {
                    token,
                    userId: user.id,
                  },
                })
                .then(() => {
                  localStorage.setItem("fcmToken", token);
                })
                .catch(() => {
                  setNotificationPopupOpen(true);
                });
            }
          );
        }
      };

      handleEnableNotifications();
    }
  }, [user]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };

  if (loading) {
    return <BackdropLoading />;
  }

  const shortNameGenerator = (name) => {
    // Remove any leading or trailing spaces
    name = name.trim();

    // Use a regular expression to find all words in the name
    const words = name.match(/[A-Za-z]+/g);

    if (words.length === 1) {
      // If there's only one word, return the first two characters: first uppercase, second lowercase
      return (
        words[0].charAt(0).toUpperCase() + words[0].charAt(1).toLowerCase()
      );
    } else {
      // If there are multiple words, initialize an empty string for the short name
      let shortName = "";

      // Loop through each word (up to two words)
      for (let i = 0; i < Math.min(words.length, 2); i++) {
        // Get the first character of each word and convert to uppercase
        const firstChar = words[i][0].toUpperCase();

        // Add the first character to the short name
        shortName += firstChar;
      }

      return shortName;
    }
  };

  const handleEnableNotifications = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      setNotificationPopupOpen(false);

      await fetchToken(
        () => {},
        async (token) => {
          await api.post("/subscribe", {
            token,
            userId: user.id,
          });

          localStorage.setItem("fcmToken", token);

          toast.success(i18n.t("layout.notification.success"));
        },
        () => {
          toastError({
            message: i18n.t("layout.notification.error"),
          });
        }
      );
    } else {
      toast.error(i18n.t("layout.notification.error"));
    }
  };

  const handleCloseNotificationPopup = () => {
    setNotificationPopupOpen(false);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={notificationPopupOpen}
        onClose={handleCloseNotificationPopup}
      >
        <DialogTitle>{i18n.t("layout.notification.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {i18n.t("layout.notification.dialog.text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEnableNotifications}
          >
            {i18n.t("layout.notification.dialog.button")}
          </Button>
          <Button onClick={handleCloseNotificationPopup}>
            {i18n.t("layout.notification.dialog.cancel")}
          </Button>
        </DialogActions>
      </Dialog>

      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems drawerClose={drawerClose} />
        </List>
        <Divider />
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        elevation={0}
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        style={{ backgroundColor: "#FFF" }}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.title}>
            <img
              src={`/${getWhiteLabelLogo()}`}
              alt="Logo"
              style={{ width: "100px" }}
            />
          </div>
          <div className={classes.chips}>
            {user.queues && user.queues?.length > 0
              ? user.queues.length < 4
                ? user.queues.map((queue) => (
                    <Chip
                      key={queue.id}
                      size="small"
                      className={classes.chip}
                      label={queue.name}
                      style={{
                        backgroundColor: queue.color,
                        color: "#000",
                      }}
                    />
                  ))
                : user.queues.map((queue) => (
                    <Tooltip
                      key={queue.id}
                      title={queue.name}
                      placement="bottom"
                      arrow
                    >
                      <Chip
                        key={queue.id}
                        size="small"
                        className={classes.chip}
                        label={shortNameGenerator(queue.name)}
                        style={{
                          backgroundColor: queue.color,
                          color: "#000",
                        }}
                      />
                    </Tooltip>
                  ))
              : null}
          </div>
          {user.id && <NotificationsPopOver />}
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            {user.name && (
              <Typography
                className={classes.userName}
                variant="caption"
                color="inherit"
                noWrap
              >
                {user.name}
                <br />
              </Typography>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;
